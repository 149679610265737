import React, { useEffect, useState } from "react";

import "./confirmationPage.scss";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { Button } from "../../components/button/button";

export default function ConfirmationPage() {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>({});

  const { dateTimeString, questName, address } = data;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (localStorage.getItem("quest-confirmation")) {
      setData(JSON.parse(localStorage.getItem("quest-confirmation") as string));
    }
  }, []);

  return (
    <section className="confirmation">
      <h1 className="confirmation__title">
        Отлично, ваша заявка теперь в обработке
      </h1>

      <div>
        <p>
          Ваше бронирование на квест{" "}
          <span className="confirmation__data">«{questName}»</span>{" "}
          <span className="confirmation__data">
            {dateTimeString && getDateTimeByString(dateTimeString)}
          </span>{" "}
          по адресу <span className="confirmation__data">{address}</span>{" "}
          принято. Для подтверждения сеанса мы с вами свяжемся.
        </p>
        <p>
          Если вы хотели задать нам вопрос, не дожидаясь звонка оператора, вы
          можете связаться с нами самостоятельно по номеру{" "}
          <a className="confirmation__link" href="tel:89250674023">
            8 (925) 067-40-23
          </a>
        </p>

        <p>Будьте внимательны:</p>

        <ul>
          <li>
            Заявки принимаются с 10:00 до 22:00 за два часа до начала сеанса,
          </li>
          <li>
            На поздние сеансы в 22:00, 23:30 и 01:00 мы берем предоплату в
            размере 1000₽,
          </li>
          <li>
            На квест не допускаются лица в алкогольном и (наркотическом)
            опьянении.
          </li>
        </ul>
      </div>

      <Button
        className="confirmation__button"
        onClick={() => dispatch(push("/"))}
      >
        Вернуться на главную
      </Button>
    </section>
  );
}

function getDateTimeByString(dateTimeString: string) {
  const [dateString, timeString] = dateTimeString.split(" ");

  return `${dateString} в ${timeString}`;
}
