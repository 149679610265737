import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { InfoBlock } from "./info-block";

export interface CharacteristicsItemProps {
  title: string;
  content: React.ReactNode;
  info?: React.ReactNode;
  className?: string;
}

export const CharacteristicsItem = ({
  title,
  content,
  info,
  className,
}: CharacteristicsItemProps) => {
  const [isShown, setIsShown] = useState(false);
  const infoTrigger = useRef<HTMLElement>(null);

  useEffect(() => {
    const callback = (e: MouseEvent) => {
      const target = e.target;

      if (target && target !== infoTrigger.current) {
        setIsShown(false);
      }
    };

    window.addEventListener("click", callback);

    return () => {
      window.removeEventListener("click", callback);
    };
  }, []);

  const handleMouseOver = () => {
    setIsShown(true);
  };

  const handleMouseOut = () => {
    setIsShown(false);
  };

  const handleClick = () => {
    setIsShown(!isShown);
  };

  return (
    <li className="characteristics__item">
      <span className="characteristics__title">
        {title}
        {info && (
          <>
            <span
              className="characteristics__info"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleClick}
              ref={infoTrigger}
            ></span>
            {createPortal(
              <InfoBlock
                isShown={isShown}
                position={countInfoPosition(infoTrigger.current)}
              >
                {info}
              </InfoBlock>,
              document.body
            )}
          </>
        )}
      </span>
      <div className={classNames("characteristics__content", className)}>
        {content}
      </div>
    </li>
  );
};

function countInfoPosition(target: HTMLElement | null) {
  type Position = {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };

  const infoPosition: Position = {};

  if (!target) {
    return infoPosition;
  }

  const targetPosition = target.getBoundingClientRect();

  const clientWidth = document.documentElement.clientWidth;
  const clientHeight = document.documentElement.clientHeight;

  const xCenter = clientWidth / 2;
  const yCenter = clientHeight / 2;

  if (targetPosition.x > xCenter) {
    infoPosition.right = clientWidth - targetPosition.left + 10;
  } else {
    infoPosition.left = targetPosition.right + 10;
  }

  if (targetPosition.y > yCenter) {
    infoPosition.bottom = clientHeight - targetPosition.top + 10;
  } else {
    infoPosition.top = targetPosition.bottom + 10;
  }

  return infoPosition;
}
